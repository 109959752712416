.gallery-item {
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s ease;
    position: relative;
}

.gallery-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: 18px;
    padding: 10px;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.gallery-item:hover img {
    opacity: 0.8;
}

.gallery-item:before {
    content: "";
    display: block;
    padding-top: 75%;
    /* Aspect Ratio 4:3 */
}

.gallery-item img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.gallery-item:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {

    .gallery-item img {
        /* height: 200px; */
    }
}