.about-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 40px 20px;
    /* background-color: black; */
    /* border-radius: 10px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    color: black;
  }
  
  .about-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: gold;
  }
  
  .about-container p {
    margin-bottom: 15px;
    font-size: 1.2rem;
    text-align: justify;
  }
  