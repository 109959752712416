.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

/* Individual service card styles */
.service-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: calc(33.333% - 40px);
    /* 3 cards per row with space around them */
    box-sizing: border-box;
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-image {
    width: 100%;
    height: 32vh;
    border-radius: 5px;
}

.service-name {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #333;
}

.service-details {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #666;
    text-align: left;
}

.enquiry-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.enquiry-button:hover {
    background-color: #0056b3;
}

/* Responsive adjustments */

/* Tablets and larger phones */
@media (max-width: 992px) {
    .service-card {
        width: calc(50% - 40px);
        /* 2 cards per row */
    }
    
    .service-image {
        height: 30vh;
    }
}

/* Small screens and phones */
@media (max-width: 576px) {
    .service-card {
        /* width: calc(100% - 40px); */
        width: 100% !important;
        /* 1 card per row */
    }
    .service-image {
        height: 25vh;
    }
    .section-title {
        font-size: 1.5rem;
    }

    .service-name {
        font-size: 1.25rem;
    }

    .service-details {
        font-size: 0.9rem;
    }

    .enquiry-button {
        padding: 8px 16px;
    }
}