/* app.css */

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Font and Color Theme */
body {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  background-color: #000000;
}

/* logo */
.logo img {
  display: block;
  margin: 0 auto; /* Center the logo */
  max-width: 100%;
  height: auto;
}
  
/* Links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
  color: gold !important; 
}

a:focus{
  color: gold !important;
  border-bottom: 1px solid white;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffffff;
}

/* banner image */
/* Banner Section */
.banner-section {
  position: relative;
  width: 100%;
  height: 80vh; /* Full height of the viewport */
  background: url('../public/images/car_banner_img.avif') no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff; /* White text for contrast */
}

/* Banner Content */
.banner-content {
  max-width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 8px;
}

/* Website Name */
.banner-content .website-name {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Slogan */
.banner-content .slogan {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

/* Contact Us Button */
.contact-info i{
  padding: 10px 20px;
  margin: 5px;
  background-color: green;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-us-btn:hover {
  background-color: gold;
  transform: scale(1.05); /* Slight zoom effect on hover */
}


/* Container for padding */
.container {
  padding: 15px;
}

/* Navbar */
.navbar {
  background-color: #000000d0;
  /* Dark blue background */
}

.navbar-toggler{
  background: gray !important;
}

.ml-auto.navbar-nav {
  margin-left: auto !important;
}

.navbar .navbar-brand {
  color: #fff;
}

.navbar .nav-link {
  color: #fff;
}

/* Footer */
.footer {
  background-color: #000000;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer a {
  color: #fff;
}

/* app.css */

/* Basic Reset */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* Font and Color Theme */
/* body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f5f5f5;
  line-height: 1.6;
} */

.carousel-css{
  padding: 0 !important;
}

/* Section Styles */
section {
  padding: 25px 0;
  /* background-color: rgb(0, 0, 0); */
  border-bottom: 1px solid #eaeaea;
  color: rgb(0, 0, 0);
}

/* about us */
.about-us p {
  text-align: left !important;
  padding-bottom: 5px;
  font-family: 'Georgia', serif;
  /* Change to a unique font style */
  font-size: 16px;
  position: relative;
  padding-left: 20px;
  /* Add space for the bullet point */
}

.about-us p::before {
  content: "●";
  /* Black circle */
  color: black;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(5px);
  /* Adjust vertical position */
}


/* Section Titles */
.section-title {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  text-transform: uppercase;
}

.section-title h2 {
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  display: inline-block;
  padding: 5px 15px;
  /* background-color: #000000; */
  color: #007bff;
  z-index: 2;
  font-family: fangsong !important;
}

.section-title::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background-color: #007bff;
  z-index: 1;
}

.section-title i {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  background-color: white;
  padding: 0 5px;
  z-index: 3;
  color: black(245, 218, 66);
}

/* Carousel */
.carousel-item img {
  width: 100%;
  height: auto;
  max-height: 320px;
  object-fit: cover;
}

/* About Us Section */
.about-us p {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.8;
}

/* Responsive Grid */
.services-grid,
.happy-customers-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-item,
.happy-customer-item {
  flex: 1 1 100%;
  max-width: 300px;
  text-align: center;
}

@media (min-width: 768px) {

  .service-item,
  .happy-customer-item {
    flex: 1 1 30%;
  }
}

@media (max-width: 768px) {
  .carousel-item img {
    max-height: 180px;
  }

  .banner-section{
    height: 45vh;
  }

  section {
    padding: 20px 0;
  }

  .img5 {
    height: 180px;
  }

  .section-title {
    margin-bottom: 20px;
  }

  .section-title h2 {
    font-size: 1.5rem;
    padding: 2px 8px;
  }

  .about-us p {
    padding-bottom: 3px;
    /* Change to a unique font style */
    font-size: 14px;
    padding-left: 20px;
    /* Add space for the bullet point */
  }
}

/* Footer */
.footer {
  background-color: #000000d0;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer a {
  color: #fff;
}

.footer-section {
  padding: 10px;
  min-width: 200px;
}

.footer-section h4 {
  font-size: 1.2em;
  color: gold;
  margin-bottom: 10px;
}

.footer-content{
  justify-content: center;
}

.footer-section p,
.footer-section a {
  color: #fff;
  margin: 5px 0;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-bottom {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #444;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9em;
}

.footer_logo{
  width: 230px;
}