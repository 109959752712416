.service-card-km {
  /* background-color: #f8f9fa; */
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-card-km:hover {
  transform: translateY(-10px);
}

.service-card-km:hover .book-now .line {
  background-color: gold;
}

.service-name {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: green;
  font-weight: 600;
}

.book-now {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.service-text {
  font-size: 1.2rem;
  /* Adjust the font size if needed */
  text-align: center;
}

.service-img {
  max-height: 100%;
  /* Ensures the image does not exceed the container height */
}

.service-div{
  padding: 20px;
  margin-top: 20px;
  border-radius: 6px;
}

.list-unstyled li{
  font-weight: 600;
}

.service-text h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem; /* Adjusts the font size of the heading */
  font-weight: bold;
  color: green; /* Change the color of the heading */
}

.book-now .text {
  font-weight: bold;
  font-size: x-large;
  padding: 0 10px;
  color: black;
  position: relative;
}

.book-now .line {
  height: 2px;
  background-color: white;
  flex-grow: 1;
  margin: 0 10px;
  transition: width 0.5s ease;
}

.book-now:hover .line {
  width: 100%;
}

.book-now .line:nth-child(1) {
  margin-right: 0;
}

.book-now .line:nth-child(3) {
  margin-left: 0;
}