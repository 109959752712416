/* Container for the cards */
.tours-section {
  padding: 40px 0; /* Adjusted padding */
}

/* Grid container */
.tours-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center; /* Center the cards in the section */
}

/* Card styling */
.tour-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background-color: #fff;
  height: 350px; /* Adjusted height for consistency */
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Hover effect for cards */
.tour-card:hover {
  transform: scale(1.05);
}

/* Image styling */
.tour-card img {
  width: 100%;
  height: 70%; /* Image takes up 70% of the card height */
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Image hover effect */
.tour-card img:hover {
  transform: scale(1.1);
}

/* Content section styling */
.tour-card-content {
  height: 30%; /* Content section takes up 30% of the card height */
  /* display: flex; */
  /* flex-direction: column; */
  justify-content: center;
  /* align-items: center; */
  padding: 15px;
  box-sizing: border-box;
  text-align: center; /* Center text */
}

/* Place name styling */
.tour-card-content .place-name {
  font-size: 1.6rem;
  font-weight: bold;
  display: block !important;
  /* background: linear-gradient(to right, #6e0000, #ec1212, #e60000); */
  color: black;
  background-clip: text;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  margin-bottom: 10px; /* Space between name and button */
}

/* Call now button styling */
.tour-card-content .call-now {
  padding: 5px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  /* display: flex;
  align-items: center; */
  gap: 5px;
  transition: all 0.3s ease;
}

.tour-card-content .whatsapp-now {
  padding: 5px 15px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  /* display: flex;
  align-items: center; */
  gap: 5px;
  transition: all 0.3s ease;
}

/* Call now button hover effect */
.tour-card-content .call-now:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .tours-section {
    flex-direction: column;
    align-items: center;
  }

}
