.section-title {
    margin-bottom: 40px;
    position: relative;
}

.section-title h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.happy-customers-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.happy-customer-item {
    flex: 1 1 200px;
    max-width: 200px;
    background-color: yellow;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.happy-customer-item h3 {
    font-size: 2.5rem;
    font-family: sans-serif;
    padding-top: 10px;
    margin-bottom: 5px;
    color: black;
    font-weight: 800;
}

.happy-customer-item p {
    font-size: 1.3rem;
    color: red;
    font-weight: 600;
}

/* Responsive design for smaller screens */
@media (max-width: 746px) {
    .happy-customer-item {
        flex: 1 1 calc(50% - 20px); /* Two items per row */
        max-width: calc(50% - 20px); /* Adjust width to fit two per row */
        padding: 15px; /* Reduce padding for smaller size */
    }

    .happy-customer-item h3 {
        font-size: 2rem; /* Reduce font size */
    }

    .happy-customer-item p {
        font-size: 0.9rem; /* Slightly smaller font size */
    }
}
