.contact-container {
    /* max-width: 800px; */
    margin: 0 auto;
    padding: 40px 20px;
    /* background-color: black; */
    /* border-radius: 10px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: black;
    line-height: 1.6;
}

.contact-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: gold;
}

.contact-container p {
    margin-bottom: 15px;
    font-size: 1.2rem;
    margin: 40px auto 0 auto;
    max-width: 800px;
    text-align: justify;
}

.contact-details {
    margin-top: 20px;
}

.contact-details h2 {
    font-size: 1.8rem;
    color: #444;
    margin-bottom: 10px;
    margin: 40px auto 0 auto;
    max-width: 800px;
}

.contact-details p {
    font-size: 1.2rem;
    margin: 40px auto 0 auto;
    max-width: 800px;
}

.contact-details a {
    color: #007bff;
    text-decoration: none;
}

.contact-details a:hover {
    text-decoration: underline;
}

.contact-form {
    margin: 40px auto 0 auto;
    max-width: 800px;
}

.contact-form h2 {
    font-size: 1.8rem;
    color: #444;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}

.thank-you-message {
    text-align: center;
    padding: 20px;
}

.thank-you-icon {
    font-size: 50px;
    color: green;
    margin-bottom: 10px;
}

.thank-you-message h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
}

.thank-you-message p {
    font-size: 16px;
    color: #666;
    text-align: center !important;
}